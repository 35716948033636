import BaseApplication from './base.js';
import $ from 'jquery';
import stickybits from 'stickybits';

'use strict';

export class Application extends BaseApplication {
	constructor(args = []) {
		super(args);

		this.setLog('[Extension] ', "Home");

		// sticky
		stickybits('#header', { useStickyClasses: true });

		this.setSlider();
	}

	callback() {
		this.showPerfomance();
		return null;
	}

	setSlider() {
		if (typeof window.Swiper != "undefined") {
	        const mySwiper = new Swiper('.swiper-container', {
	            effect: 'fade',
		        speed: 400,
		        spaceBetween: 0,
		        loop: true,
		        pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
		        autoplay: {
		            delay: 5000,
		        },
		        fadeEffect: {
		            crossFade: true
		        },
	        });
		}
    }
};

window.Application = new Application();
